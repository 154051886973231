import React from "react";
import css from './forestStreet.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import ForestStreetVideo from "./forest_street.mp4";
import {getRoutes} from "../../shared/routes";
import LinkButton from "../../shared/LinkButton/LinkButton";
import {getImagesFluid} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import VideoWithPreloadedImage from "../../shared/VideoWithPreloadedImage/VideoWithPreloadedImage";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_forest_street"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const ForestStreet = () => {
    const routes = getRoutes();
    const title = routes.TraceabilityForestStreet.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <VideoWithPreloadedImage
                    videoSrcURL={ForestStreetVideo}
                    placeholderImage={'forest_street.jpg'}
                    placeholderImageAlt={'forest street'}
                    imagesFluid={imagesFluid}
                />
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Harvested timber logs are being placed at the forest street for dispatch. <br/>
                    The provenance system functionality of
                    <br/><br/>
                    <div>
                        <ListItem
                            list={['log marking based on Dcode', 'image sourcing by creating a single log face fingerprint signature record', 'log measurement of']}/>
                    </div>
                    <div style={{paddingLeft: 24}}>
                        <ListItem
                            list={['single log faces diameters and length, lidar precision ', 'pile log measurement by pre-setting the length and using single log diameter size.']}/>
                    </div>
                </div>
                <LinkButton
                    label={'Learn more'}
                    route={routes.TraceabilityForestStreet}
                />
            </SectionTextWrapper>
        </div>
    )
};

export default ForestStreet;
