import React from 'react';
import css from "./listItem.module.scss";
import LeafsIcon from "../../../images/icons/leafs_symbol_icon.svg";

const ListItem = (props) => {
    const {list = [], style} = props

    return (
        <>
            {list.map((item, index) => (
                <div className={css.listItem} style={{...style}} key={index}>
                    <img src={LeafsIcon} alt="Leafs Icon"/>
                    {item}
                </div>
            ))}
        </>
    );
}

export default ListItem;