import React, {useState, useRef, useEffect} from "react";
import css from './phoneFrameVideoHorizontal.module.scss';
import {renderImageByFileName} from "../../componentsUtils";
import Video from "../Video/Video";
import PhoneFrameImg from "./phone_frame_horizontal.png";
import AppLoader from "../AppLoader/AppLoader";

const PhoneFrameVideoHorizontal = props => {
  const {
      videoSrcURL,
      videoWidth = 325,
      placeholderImage,
      placeholderImageAlt = '',
      imagesFluid = [],
  } = props;

  const mountedRef = useRef(true);

  useEffect(() => {
      return () => {
          mountedRef.current = false;
      }
  }, []);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const videoHiddenClass = showPlaceholder ? css.hidden : '';;

  return (
    <div className={css.wrapper} style={{ padding: 0.05 * videoWidth}}>
        <div className={css.videoContent} style={{ width: videoWidth }}>
          { showPlaceholder && placeholderImage &&
              <div className={css.placeholderImageWrapper}>
                  <AppLoader/>
                  {renderImageByFileName(imagesFluid, placeholderImage, placeholderImageAlt)}
              </div>
          }
          <div className={[css.videoWrapper, videoHiddenClass].join(' ')}>
              <Video
                  videoSrcURL={videoSrcURL}
                  onLoad={() => {
                      if (mountedRef.current) {
                          setShowPlaceholder(false)
                      }
                  }}
              />
          </div>
          <div className={css.phoneFrame}>
              <img src={PhoneFrameImg} alt=""/>
          </div>
      </div>
    </div>
  )
};

export default PhoneFrameVideoHorizontal;