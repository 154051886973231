import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import LinkButton from "../../shared/LinkButton/LinkButton";
import Text from "../../shared/Text/Text";
import React from "react";
import css from "./supplyChain.module.scss";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_supply_chain"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1015, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

export const SupplyChain = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Traceability.sections.supplyChain.title;
    return (

        <div className={css.wrapper}>
            <SectionTextWrapper desktopMaxTextWidth={'unset'} desktopWidth={'100%'} isBoldText={false}>
                <SectionMobileTitle>{title}</SectionMobileTitle>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text textIsBold={false}>
                    Collecting data at the "First Mile" is crucial for maintaining transparency in the supply chain and
                    verifying the geolocation of timber sources. <br/><br/>
                    This information about the origin can be transferred to the sawmill and linked to specific batches
                    of
                    goods, packages or even to individual boards. <br/><br/>
                    Once this geolocation data is linked, the information flow is complete, ensuring a documented chain
                    of custody.
                </Text>
            </SectionTextWrapper>
            <SectionImageVideoWrapper desktopWidth={'100%'}>
                <div className={css.imageVideoWrapperDesktop}>
                    {renderImageByFileName(imagesFluid, 'supply_chain.png', 'supply chain')}
                </div>
                <div className={css.imageVideoWrapperMobile}>
                    {renderImageByFileName(imagesFluid, 'supply_chain_mobile.png', 'supply chain')}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopMaxTextWidth={'unset'} desktopWidth={'100%'}>
                <Text textIsBold={false}>
                    Stakeholders can then use this data to trace the final wood product.<br/><br/>
                    This system enables easier handling of downstream audits and automation of reporting processes.
                </Text>
            </SectionTextWrapper>
        </div>
    )
}
