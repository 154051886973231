import React, {useState, useRef, useEffect} from "react";
import css from './videoWithPreloadedImage.module.scss';
import {renderImageByFileName} from "../../componentsUtils";
import Video from "../Video/Video";
import AppLoader from "../AppLoader/AppLoader";

const VideoWithPreloadedImage = props => {
  const {
      videoSrcURL,
      maxWidth = '460px',
      borderRadius = '8px',
      placeholderImage,
      placeholderImageAlt = '',
      imagesFluid = [],
  } = props;

  const mountedRef = useRef(true);

  useEffect(() => {
      return () => {
          mountedRef.current = false;
      }
  }, []);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const videoHiddenClass = showPlaceholder ? css.hidden : '';

  return (
    <div className={css.wrapper} style={{ maxWidth, borderRadius }}>
      <div className={css.videoContent}>
          { showPlaceholder && placeholderImage &&
              <div className={css.placeholderImageWrapper}>
                  <AppLoader/>
                  {renderImageByFileName(imagesFluid, placeholderImage, placeholderImageAlt)}
              </div>
          }
          <div className={[css.videoWrapper, videoHiddenClass].join(' ')}>
              <Video
                  videoSrcURL={videoSrcURL}
                  onLoad={() => {
                      if (mountedRef.current) {
                          setShowPlaceholder(false)
                      }
                  }}
              />
          </div>
      </div>
    </div>
  )
};

export default VideoWithPreloadedImage;