import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './forest2.module.scss';
import {getImagesFluid} from '../../componentsUtils';
import {getRoutes} from "../../shared/routes";
import VideoWithPreloadedImage from "../../shared/VideoWithPreloadedImage/VideoWithPreloadedImage";
import Video1 from "./forest_plots_of_land_creation.mp4";
import Video2 from "./display_the_selected_areas_on_the_web_client.mp4";
import Video3 from "./deforestation_analysis.mp4";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_pages_forest"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Forest2 = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    return (

        <div className={css.wrapper}>
            <div className={css.imageSectionWrapper}>
                <div className={css.imageWrapper}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video1}
                        placeholderImage={'forest_plots_of_land_creation.jpg'}
                        placeholderImageAlt={'forest plots of land creation'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        Forest plots of land creation by setting the geo-location polygon data.
                    </div>
                </div>
                <div className={css.imageWrapper}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video2}
                        placeholderImage={'display_the_selected_areas_on_the_web_client.jpg'}
                        placeholderImageAlt={'display the selected areas on the web client'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        Display the selected areas on the Web Client Interface screen and view available information
                        and data created by the timber log authentication and verification system functionality.
                    </div>
                </div>
                <div className={css.imageWrapper}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video3}
                        placeholderImage={'deforestation_analysis.jpg'}
                        placeholderImageAlt={'deforestation analysis'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        Use satellite images preview in selected timeframes for deforestation analysis.
                    </div>
                </div>
            </div>

        </div>
    )
};

export default Forest2;
