import React, {useEffect} from "react";
import css from "./video.module.scss";

const Video = props => {
    const {
        videoSrcURL,
        controls = false,
        loop = true,
        muted = true,
        onLoad = () => {},
        onEnd = () => {},
        videoClassName = ""
    } = props;

    useEffect(() => {
        const videos = document.getElementsByTagName('video');
        const currentVideo = Array.from(videos).find(video => {
            return video.getAttribute('src') === videoSrcURL
        });
        if (currentVideo) {
            // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
            // Show loading animation.
            const playPromise = currentVideo.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    onLoad();
                })
                .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                });
            }
            currentVideo.addEventListener('loadeddata', function() {
               // Video is loaded and can be played
                onLoad();
            }, false);
            currentVideo.addEventListener('ended', function() {
                // NOTE: event fired with loop=false only
                onEnd(currentVideo);
            }, false);
        }
    }, []);

    return (
        <div className={css.video}>
            <video
                src={videoSrcURL}
                controls={controls}
                loop={loop}
                muted={muted}
                playsInline={true}
                autoPlay="autoplay"
                className={videoClassName}
            />
        </div>
    )
};

export default Video;
