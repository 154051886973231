import {Link} from "gatsby";
import React from "react";
import css from "./subpageLink2.module.scss";

const SubpageLink2 = props => {
    const {
        children,
        link,
        section = null,
        spaces = true
    } = props;
    const spaceToRender = spaces ? " " : "";

    return (
        <>
            {spaceToRender}
            <Link
                to={section !== null ? `${link}#${section}` : `${link}`}
                className={css.wrapper}
            >
                {children}
            </Link>
            {spaceToRender}
        </>
    );
};
export default SubpageLink2;

