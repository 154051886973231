import React from "react";
import SideboardCuttingArea from "../SideboardCuttingArea/SideboardCuttingArea";
import MainboardCuttingArea from "../MainboardCuttingArea/MainboardCuttingArea";


const AtTheSawmill2 = () => {
    return (
        <>
            <SideboardCuttingArea/>
            <MainboardCuttingArea/>
        </>
    )
};

export default AtTheSawmill2;
