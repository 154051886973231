import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './mainboardCuttingArea.module.scss';
import {getImagesFluid} from '../../componentsUtils';
import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import MainboardCuttingAreaVideo from "./MainboardCuttingArea_video.mp4";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_MainboardCuttingArea"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const MainboardCuttingArea = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'Mainboard Cutting Area';

    return (

        <div className={css.wrapper}>
            <SectionImageVideoWrapper desktopWidth={'55%'}>
                <div className={css.imageVideoWrapper}>
                    <PhoneFrameVideo
                        videoSrcURL={MainboardCuttingAreaVideo}
                        placeholderImage={'MainboardCuttingArea_video_placeholder.jpg'}
                        imagesFluid={imagesFluid}
                        videoWidth={200}
                    />
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <Text textIsBold={false}>
                    This process is robust and capable of handling complex scenarios even where a single delivery may involve multiple origins.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default MainboardCuttingArea;
