import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import Forest from "../components/sections/Forest/Forest";
import Logistic from "../components/sections/logistic/Logistic";
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import SmallFlowDiagram from "../components/sections/SmallFlowDiagram/SmallFlowDiagram";
import ForestStreet from "../components/sections/ForestStreet/ForestStreet";
import SawmillTrader from "../components/sections/sawmillTrader/SawmillTrader";
import AtTheSawmill from "../components/sections/AtTheSawmill/AtTheSawmill";
import Dcode from "../components/sections/Dcode/Dcode";
import ForestStreet2 from "../components/sections/ForestStreet2/ForestStreet2";
import Forest2 from "../components/sections/Forest2/Forest2";
import SawmillTrader2 from "../components/sections/SawmillTrader2/SawmillTrader2";
import AtTheSawmill2 from "../components/sections/AtTheSawmill2/AtTheSawmill2";
import {
    LegalityProofGeolocationEvidenceSections
} from "../components/sections/LegalityProofGeolocationEvidenceSections/LegalityProofGeolocationEvidenceSections";
import {SupplyChain} from "../components/sections/SupplyChain/SupplyChain";

const Traceability = () => {
    const routes = getRoutes().Traceability;
    const pageTitle = routes.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{routes.pageTitle}</PageTitle>

            <LegalityProofGeolocationEvidenceSections
                legalityProofSectionId={routes.sections.timberIdLegalityProof.anchor}
                geolocationEvidenceSectionId={routes.sections.geolocationEvidence.anchor}
            />

            <div id={routes.sections.supplyChain.anchor}>
                <SupplyChain/>
            </div>

            <Dcode/>
            <ForestStreet2/>

            <div id={routes.sections.forest.anchor}>
                <Forest/>
            </div>
            <Forest2/>

            <div id={routes.sections.logistic.anchor}>
                <Logistic/>
            </div>

            <SmallFlowDiagram/>

            {/*<div id={routes.sections.forestStreet.anchor}>
                <ForestStreet/>
            </div>*/}

            <div id={routes.sections.sawmillTrader.anchor}>
                <SawmillTrader/>
            </div>
            <SawmillTrader2/>

            <div id={routes.sections.atTheSawmill.anchor}>
                <AtTheSawmill/>
            </div>
            <AtTheSawmill2/>
            {/*<Measurement/>*/}

            {/*<Marking/>*/}

            {/*<Inventory/>*/}

            <AdditionalInformation/>

            <Footer/>

        </Layout>
    );
};

export default Traceability;
