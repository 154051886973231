import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './sawmillTrader.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";
import SubpageLink2 from "../../shared/InternalLinks/SubpageLink2";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import LinkButton from "../../shared/LinkButton/LinkButton";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_sawmill_trader"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SawmillTrader = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Traceability.sections.sawmillTrader.title;
    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'44%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'sawmill_trader.png', 'sawmill trader')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'50%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    A single log Dcode marking enables the capability to efficiently manage the inventory, segregate and transfer the provenance dataset to every transaction
                    <br/><br/>
                    The supply chains stakeholders have a vital interest in a highly reliable and verifiable information flow, linked to the traded commodities, providing authentication check on demand
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default SawmillTrader;
