import React from "react";
import css from "./geolocationEvidence.module.scss";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import Text from "../../shared/Text/Text";
import LinkButton from "../../shared/LinkButton/LinkButton";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_geolocation_evidence"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 425, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

export const GeolocationEvidence = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    // const title = routes.Traceability.sections.forest.title.replace(/& /g, "&\u00a0");
    const title = routes.Traceability.sections.geolocationEvidence.title;

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.mobileTitle}>{title}</div>
                <SectionImageVideoWrapper desktopWidth={'100%'}>
                    <div className={css.imageVideoWrapper}>
                        {renderImageByFileName(imagesFluid, 'geolocation_evidence.png', 'forest')}
                        {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                    </div>
                </SectionImageVideoWrapper>
                <SectionTextWrapper desktopWidth={'100%'}>
                    <div className={css.desktopTitle}>{title}</div>
                    <Text textIsBold={false}>
                        "First Mile" data creation importance is crucial for tracking each delivery, from the forest to all next for a of timber transformation,
                        creating a new product, based on a HS code, and confirming the provenance, linked with the forest plot geolocation
                    </Text>
                    <br/>
                </SectionTextWrapper>
            </div>
        </>
    )
}
