import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";
import css from "./legalityProof.module.scss";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import Text from "../../shared/Text/Text";
import LinkButton from "../../shared/LinkButton/LinkButton";
import React from "react";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_legality_proof"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 425, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;



export const LegalityProof = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    // const title = routes.Traceability.sections.forest.title.replace(/& /g, "&\u00a0");
    const title = routes.Traceability.sections.timberIdLegalityProof.title;
    return (
        <div className={css.wrapper}>
            <div className={css.mobileTitle}>{title}</div>
            <SectionImageVideoWrapper desktopWidth={'100%'}>
                <div className={css.imageVideoWrapper}>
                        {renderImageByFileName(imagesFluid, 'legality_proof.png', 'forest')}
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'100%'}>
                <div className={css.desktopTitle}>{title}</div>
                <Text textIsBold={false}>
                    Dcode is the technology providing the method of creating and securing the legality evidence.
                    <br/> <br/>
                    The geolocation dataset delivers a reference linked with the forest plot.
                    <br/> <br/>
                    Sourcing the data at the timber harvesting time, Dcode the unfakeable method for single log authentication.
                </Text>
            </SectionTextWrapper>
        </div>
    )
}
