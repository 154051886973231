import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './smallFlowDiagram.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_small_flow_diagram"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SmallFlowDiagram = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.content}>
                <div className={css.bigContent}>
                    {renderImageByFileName(imagesFluid, 'Digital_Delivery_Note.png', 'DDN')}
                </div>
                <div className={css.smallContent}>
                    {renderImageByFileName(imagesFluid, 'Digital_Delivery_Note_Mobile.png', 'DDN mobile')}
                </div>
            </div>
        </div>
    );
};

export default SmallFlowDiagram;
