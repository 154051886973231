import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './sideboardCuttingArea.module.scss';
import {getImagesFluid, viewports} from '../../componentsUtils';
import PhoneFrameVideoHorizontal from "../../shared/PhoneFrameVideo/PhoneFrameVideoHorizontal";
import SideboardCuttingAreaVideo from "./SideboardCuttingArea_video.mp4";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import useBreakpoint from "../../hooks/useBreakpoint";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_SideboardCuttingArea"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SideboardCuttingArea = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const breakpoint = useBreakpoint();
    const {phoneViewport} = viewports;
    const videoWidth = (breakpoint.viewport === phoneViewport) ? 290 : 433;

    const title = 'Sideboard Cutting Area';

    return (

        <div className={css.wrapper}>
            <SectionImageVideoWrapper desktopWidth={'55%'}>
                <div className={css.imageVideoWrapper}>
                    <PhoneFrameVideoHorizontal
                        videoSrcURL={SideboardCuttingAreaVideo}
                        placeholderImage={'SideboardCuttingArea_video_placeholder.jpg'}
                        imagesFluid={imagesFluid}
                        videoWidth={videoWidth}
                    />
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <Text textIsBold={false}>
                    During the production, geolocation data is assigned to each production batch, package, or even individual board, ensuring authentication.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default SideboardCuttingArea;
