import css from "./legalityProofGeolocationEvidenceSections.module.scss"
import {LegalityProof} from "../LegalityProof/LegalityProof";
import {GeolocationEvidence} from "../GeolocationEvidence/GeolocationEvidence";
import React from "react";

export const LegalityProofGeolocationEvidenceSections = ({legalityProofSectionId, geolocationEvidenceSectionId}) => {
    return (
        <section className={css.section}>
            <div id={legalityProofSectionId}>
                <LegalityProof/>
            </div>
            <div id={geolocationEvidenceSectionId}>
                <GeolocationEvidence/>
            </div>
        </section>
    )
}
