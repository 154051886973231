import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './dcode.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";
import SubpageLink2 from "../../shared/InternalLinks/SubpageLink2";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import LinkButton from "../../shared/LinkButton/LinkButton";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcode"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Dcode = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Traceability.sections.dcode.title;

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'dcode.png', 'Dcode')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'48%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Dcode - encrypting variable code data sourced <br />by positioning satellite signals accessible globally
                    <br/><br/>
                    <div>
                        <ListItem
                            list={[
                                'Geolocation accuracy of 2m',
                                'Timestamp accuracy of one second'
                            ]}/>
                    </div>
                    <br/>
                    Dcode benefits
                    <br/><br/>
                    <div>
                        <ListItem
                            list={[
                                'Created when being applied',
                                'Tamper-proof, unfakeable data',
                                'Eco-friendly, elimination of any consumables. ',
                                'Cost-effective'
                            ]}/>
                    </div>
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default Dcode;
