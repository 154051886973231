import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './sawmillTrader2.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import {getRoutes} from "../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sawmill_trader"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SawmillTrader2 = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    return (
        <div className={css.wrapper}>
            <div className={css.imageSectionWrapper}>
                <div className={css.imageWrapper}>
                    {renderImageByFileName(imagesFluid, 'entry.png', 'sawmill trader')}
                </div>
                <div className={css.imageWrapper}>
                    {renderImageByFileName(imagesFluid, 'log_yard_3.png', 'sawmill trader')}
                </div>
                <div className={css.imageWrapper}>
                    {renderImageByFileName(imagesFluid, 'log_yard_4.png', 'sawmill trader')}
                </div>
            </div>

            <div className={css.text}>
                <div>Single log identification allows to segregate inventory by provenance, including geo-location
                    and harvesting date positively impacts sales and stock turn over time and inventory management.
                    <br/><br/>
                    In case of non-compliant delivery an obligation of separation of questioned timber logs for
                    official audit must be performed.
                </div>
            </div>

        </div>
    )
};

export default SawmillTrader2;
