import React, {useEffect} from "react";
import {getLink, getRoutes} from "../routes";
import {Link} from 'gatsby';
import MainButton, {mainButtonArrow} from "../MainButton/MainButton";

const LinkButton = props => {
    const {
        label = 'Contact us',
        route = getRoutes().Contact,
        section = null
    } = props;

    const link = section === null ? getLink([route]) : getLink([route, section], true);

    return (
        <Link to={link}>
            <MainButton label={label} arrow={mainButtonArrow.right}/>
        </Link>
    );
};

export default LinkButton;
